import { Home } from "./components/Home";
import { About } from "./components/About";
import { Contacts } from "./components/Contacts";
import { Oferta } from "./components/Oferta";

const AppRoutes = [
    {
        index: true,
        path: "/",
        element: <Home />
    },
    {
        index: true,
        path: "About",
        element: <About />
    },
    {
        index: true,
        path: "Contacts",
        element: <Contacts />
    },
    {
        index: true,
        path: "Oferta",
        element: <Oferta />
    }
];

export default AppRoutes;