import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function withParams(Component) {
    return props => <Component {...props} location={useLocation()} />;
}

class NaviLink extends React.Component {
    render() {
        var isActive = (this.props.location.pathname === this.props.to);
        var className = 'navi-link button ' + (isActive ? 'active' : '');
        return (
            <Link className={className} {...this.props} href={this.props.to}>
                {this.props.children}
            </Link>
        );
    }
}

export default withParams(NaviLink);