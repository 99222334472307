import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import NaviLink from './NaviLink';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="border-bottom box-shadow nav-header" container>
                    <a className="navi-link email" href="mailto:cs@indutech.ru" >Email: cs@indutech.ru</a>
                    <a className="navi-link call" href="tel:+88005509762">Телефон: 8 800 550-97-62</a>
                </Navbar>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom" container light>
                    <NavbarBrand tag={Link} to={{ pathname: "/" }}><img width="200px" src="/images/logo_main_003.svg" alt="Pneumoindutech Logo" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NaviLink location={this.props.location} to="/">Главная</NaviLink>
                            </NavItem>
                            <NavItem>
                                <a href="https://catalog.pneumoindutech.ru" className="navi-link button" target="_blank" rel="noreferrer" >Каталог</a>
                            </NavItem>
                            <NavItem>
                                <NaviLink location={this.props.location} to="/Oferta">Договор&nbsp;поставки</NaviLink>
                            </NavItem>
                            <NavItem>
                                <NaviLink location={this.props.location} to="/About">О&nbsp;нас</NaviLink>
                            </NavItem>
                            <NavItem>
                                <NaviLink location={this.props.location} to="/Contacts">Контакты</NaviLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
