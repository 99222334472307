import React, { Component } from 'react';
import '../css/home.css';

export class Home extends Component {
    static displayName = Home.name;
    render() {
        return (
            <div className="main-page">
                <center>
                    <div className="main-page-container">
                        <div className="main-page-place-holder">
                            <div className="main-page-title">Пневматические компоненты</div>
                            <div className="main-page-text">для промышленной автоматизации</div>
                            <div className="main-page-buttons">
                                <a href="https://catalog.pneumoindutech.ru" target="_blank" rel="noreferrer">Каталог продукции</a>
                                <a href="https://indutech.ru" target="_blank" rel="noreferrer">Подробнее о компании</a>
                            </div>
                        </div>
                    </div>
                </center>
            </div>
        );
    }
}
