import React, { Component } from 'react';

export class Footer extends Component {
    static displayName = Footer.name;
    render() {
        return (
            <div className="footer">
                <div className="footer-container">
                    <div className="footer-address">
                        <div className="footer-border">
                            <h4>Наш адрес</h4>
                            <div className="footer-body">
                                г. Санкт-Петербург,<br />
                                Кондратьевский пр., д. 15 корп. 3,<br />
                                БЦ "Кондратьевский", офис 226-ц
                            </div>
                        </div>
                    </div>
                    <div className="footer-contacts">
                        <h4>Контакты</h4>
                        <div className="footer-body">
                            Email:&nbsp;&nbsp;<a className="email" href="mailto:cs@indutech.ru">cs@indutech.ru</a> — размещение заказов, сроки и цены<br />
                            Телефон:&nbsp;&nbsp;<a className="call" href="tel:+88005509762">8 800 550-97-62</a><br />
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">©2023 ООО "ИндуТех"</div>
            </div>
        );
    }
}
