import React, { Component } from 'react';
import '../css/oferta.css';

export class Oferta extends Component {
    static displayName = Oferta.name;
    render() {
        return (
            <div className="oferta">
                <h6>Договор поставки</h6>
                <h6>(публичная оферта в редакции от 20 сентября 2020г.)</h6>
                <div className="oferta-header">
                    <div className="oferta-header-left">г. Санкт-Петербург</div>
                    <div className="oferta-header-right">&nbsp;</div>
                </div>
                <p>Общество с ограниченной ответственностью «Индустриальные Технологии», именуемое далее Поставщик, в лице Генерального директора Верещагина Евгения Владимировича, действующего на основании Устава, предлагает любому юридическому лицу, именуемому далее Покупатель, заключить настоящий договор, являющийся публичной офертой. Поставщик и Покупатель совместно именуются Стороны.</p>
                <p>1. Предмет договора</p>
                <p>1.1. Поставщик передает в собственность Покупателя, а Покупатель оплачивает и принимает Продукцию производственно-технического назначения (далее – Продукция) в соответствии с условиями настоящего Договора.</p>
                <p>1.2. Ассортимент, количество, цена Продукции определяются по соглашению Сторон и указываются в счете, который является частью Договора. Счету присваивается уникальный номер, который является также номером Договора. Копия счета, переданная по факсимильной связи или по электронной почте Покупателю, признается Сторонами верной и равнозначной оригиналу, если установлено, что счет получен от Поставщика.</p>
                <p>2. Порядок оплаты</p>
                <p>2.1. Покупатель оплачивает Продукцию на условиях 100% предоплаты путем безналичного перечисления денежных средств на расчетный счет Поставщика. В графе "назначение платежа" платежного поручения Покупатель указывает номер счета.</p>
                <p>2.2. Датой оплаты по Договору считается дата зачисления денежных средств на расчетный счет Поставщика.</p>
                <p>3. Условия поставки</p>
                <p>3.1. Поставка Продукции осуществляется в течение согласованного Сторонами срока, указанного в счете, либо в разумный срок, если срок поставки не указан.</p>
                <p>3.2. Поставка по настоящему Договору осуществляется на условиях выборки Продукции со склада Поставщика, если Сторонами письменно не согласовано иное.</p>
                <p>3.3. Если Поставщик, по согласованию с Покупателем, осуществляет доставку по указанному адресу Покупателя, то стоимость доставки включается в цену Продукции. В случае невозвращения Покупателем экземпляров УПД и/или транспортной накладной с отметкой о принятии, либо не предоставления мотивированного отказа от приемки Продукции в течение 30 (тридцати) дней с момента отгрузки Продукции Покупателю, Продукция считается поставленной и принятой Покупателем без возражений по количеству и качеству (за исключением скрытых недостатков, которые могут быть заявлены в течение гарантийного срока).</p>
                <p>4. Гарантийные обязательства</p>
                <p>4.1. Поставщик гарантирует качество Продукции в течение 12 (двенадцати) месяцев с момента ее передачи Покупателю при условии соблюдения Покупателем правил эксплуатации и хранения.</p>
                <p>4.2. Поставщик не несет ответственности за недостатки Продукции, появившиеся в результате неправильного хранения или несоблюдения правил эксплуатации. Также Поставщик не несет ответственности и не обязан возмещать убытки в виде упущенной выгоды, убытки в результате простоя и иные непрямые убытки.</p>
                <p>5. Ответственность Сторон</p>
                <p>5.1. Стороны должны исполнять свои обязательства по Договору надлежащим образом. За нарушение обязательств по Договору Стороны несут ответственность в соответствии с действующим законодательством РФ. Суммарная исключительная ответственность Стороны по Договору не может превышать 10% суммы Продукции, в отношении которой возникло соответствующее нарушение. Настоящим Стороны подтверждают, что вышеуказанный максимальный размер неустойки является соразмерным последствиям нарушения обязательств.</p>
                <p>5.2. Поставщик обязуется возвратить полученные денежные средства по счету в течение 5 (пяти) рабочих дней с момента принятия решения о расторжении Договора в порядке, предусмотренном п. 8.1. настоящего Договора.</p>
                <p>6. Форс-мажор</p>
                <p>6.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по Договору, если надлежащее исполнение оказалось невозможным вследствие непреодолимой силы, то есть возникших после заключения Договора обстоятельств чрезвычайного характера, которые Стороны не могли предвидеть или предотвратить.</p>
                <p>6.2. В случае наступления обстоятельств, предусмотренных в п. 6.1. Договора, срок выполнения Сторонами обязательств по Договору отодвигается соразмерно времени, в течение которого действуют эти обстоятельства.</p>
                <p>7. Срок действия Договора</p>
                <p>7.1. Договор считается заключенным с даты оплаты. Оплата счета является акцептом оферты в соответствии со ст. 438 ГК РФ и означает ознакомление и согласие Покупателя со всеми пунктами Договора. Акцепт оферты является полным и безоговорочным.</p>
                <p>7.2. Договор действует до выполнения Сторонами своих обязательств.</p>
                <p>8. Расторжение Договора</p>
                <p>8.1. Договор может быть расторгнут по соглашению Сторон.</p>
                <p>8.2. Поставщик вправе отозвать настоящую оферту в течение 10 (десяти) рабочих дней с момента ее акцепта. Отзыв оферты осуществляется путем возврата Покупателю, уплаченных им денежных средств.</p>
                <p>9. Заключительные положения</p>
                <p>9.1. Поставщик вправе самостоятельно вносить изменения и дополнения в Договор до его акцепта, информируя о таких изменениях Покупателя путем публикации новой версии Договора на официальном сайте Поставщика <a href="https://www.pneumoindutech.ru">https://www.pneumoindutech.ru</a></p>
                <p>10. Реквизиты Поставщика</p>
                <p>ООО «ИндуТех»</p>
                <p>ИНН/КПП 7804324760/780401001</p>
                <p>Р/с 40702810329060007814 в Филиале «Центральный» Банка ВТБ (ПАО) в г. Москве </p>
                <p>БИК 044525411 К/с 30101810145250000411</p>
                <p>Юридический адрес: 195197, г. Санкт-Петербург, пр-т Кондратьевский, д. 15 к. 3, Литера И, офис 226-Ц</p>
                <p>Почтовый адрес:195197, г. Санкт-Петербург, пр-т Кондратьевский, д. 15 к. 3, Литера И, офис 226-Ц</p>
                <p>&nbsp;</p>
            </div>
        );
    }
}
