import React, { Component } from 'react';

export class About extends Component {
    static displayName = About.name;
    render() {
        return (
            <div>
                <h3>О нас</h3>
                <p>
                    Инжиниринговая торгово-производственная компания ООО «ИндуТех» была основана в 2005 году.
                    На сегодняшний день мы развиваем несколько направлений в области обеспечения производственных предприятий высококачественной продукцией.
                    Наши сотрудники – эксперты в области промышленной автоматизации с многолетним опытом работы с ведущими производителями оборудования.
                </p>
                <p>
                    Одним из основных направлений деятельности компании является разработка решений и подбор оборудования для автоматизированных систем
                    управления технологическими процессами, проектирование и сборка шкафов управления,
                    а также поставки пневматических компонентов и сопутствующих аксессуаров.
                </p>
                <p>
                    В рамках этого направления мы создали свой бренд Indutech. Продукция под этим брендом сконструирована в соответствии с требованиями рынка.
                    Наши сотрудники с многолетним опытом помогут вам сделать верный выбор. Для этого работают отдел продаж, отдел технической поддержки,
                    конструкторский отдел, производство, служба сервиса и ОТК.
                </p>
                <p>
                    Основной склад и производство расположены в Санкт-Петербурге. На складе поддерживается достаточный ассортимент пневматических
                    компонентов основных типоразмеров. На собственном производстве мы осуществляем сборку шкафов управления по требованиям
                    заказчика в кратчайшие сроки.
                </p>
            </div>
        );
    }
}
