import React, { Component } from 'react';
import '../css/contacts_form.css';

export class Contacts extends Component {
    static displayName = Contacts.name;
    fieldList =
    [
        { name: "companyName", label: "Название компании", type: "text", isRequired: false, isHidden: false, isFullLine: false },
        { name: "firstName", label: "Имя", type: "text", isRequired: false, isHidden: false, isFullLine: false },
        { name: "phone", label: "Телефон", type: "text", isRequired: false, isHidden: false, isFullLine: false },
        { name: "email", label: "Email", type: "text", isRequired: true, isHidden: false, isFullLine: false },
        { name: "message", label: "Ваше сообщение", type: "textarea", isRequired: true, isHidden: false, isFullLine: true },
        { name: "agreement", label: "Даю согласие на обработку персональных данных", type: "checkbox", isRequired: true, isHidden: false, isFullLine: true },
        { name: "humanField", label: "Я не робот", type: "checkbox", isRequired: false, isHidden: true, isFullLine: true }
    ]
    constructor(props) {
        super(props);
        var state = {
            errorText: "",
            successText: "",
            errorList: []
        }
        this.fieldList.forEach(field => {
            var curValue = (field.type === "checkbox" ? false : "");
            state[field.name] = this.formFieldState(curValue);
        });
        this.state = state;
    }
    formFieldState(valueIn, isError = false, errorText = "") {
        return { value: valueIn, isError: isError, errorText: errorText };
    }
    isErrorField(field) {
        if (!this.state[field.name]) {
            return true;
        } else if (!this.state[field.name].value) {
            return true;
        } else if (field.type === "text") {
            return this.isErrorTextField(field.name);
        } else if (field.type === "textarea") {
            return this.isErrorTextField(field.name);
        } else if (field.type === "checkbox") {
            return this.isErrorCheckbox(field.name);
        }
        return true;
    }
    isErrorTextField(fieldName) {
        return (this.state[fieldName].value.trim() === "");
    }
    isErrorCheckbox(fieldName) {
        return !(this.state[fieldName].value === true);
    }
    sendForm = () => {
        var errorList = [];
        this.fieldList.forEach(field => {
            if (field.name in this.state) {
                if (field.isRequired === true) {
                    if (
                        !this.state[field.name]
                        || this.isErrorField(field) === true
                    ) {
                        errorList.push("Поле \"" + field.label + "\" не заполнено");
                    }
                }
            }
        });
        this.setState({errorList: errorList});
        if (errorList.length === 0) {
            var contactForm = {};
            this.fieldList.forEach(field => {
                contactForm[field.name] = this.state[field.name];
            });
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ contactForm: contactForm, commonError: '' })
            };
            fetch('api/v1/contactform', requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Ошибка получения данных: Код ответа ${response.status}`);
                    }
                    return response.json();
                })
                .then((formResult) => {
                    if (formResult.commonError) {
                        errorList.push(formResult.commonError);
                    }
                    if (formResult.contactForm) {
                        this.fieldList.forEach(field => {
                            if (
                                formResult.contactForm[field.name]
                                && formResult.contactForm[field.name].errorText
                            ) {
                                errorList.push(formResult.contactForm[field.name].errorText);
                            }
                        });
                    }
                    var state = { errorList: errorList };
                    if (errorList.length === 0) {
                        state = { errorList: errorList, successText: "Заявка успешно отправлена!" };
                        this.fieldList.forEach(field => {
                            var curValue = (field.type === "checkbox" ? false : "");
                            state[field.name] = this.formFieldState(curValue);
                        });
                    }
                    this.setState(state);
                })
                .catch((err) => {
                    errorList.push('Ошибка получения данных ' + err);
                    this.setState({ errorList: errorList });
                });
        }
    }
    onFieldsChange = (event) => {
        var curValue = event.target.value;
        var thisField = {};
        var isFound = false;
        this.fieldList.forEach(field => {
            if (isFound === false && event.target.name === field.name) {
                thisField = field;
                isFound = true;
            }
        });
        if (isFound === false) {
            this.setState({ errorText: "Ошибка: Неизвестное поле \"" + event.target.name + "\"" });
            return;
        }
        if (thisField.type === "checkbox") {
            curValue = (event.target.checked === true ? true : false);
        }
        var stateValue = { errorText: "", [thisField.name]: this.formFieldState(curValue) };
        this.setState(stateValue);
    }

    render() {
        return (
            <div className="contact-form">
                <div className="contact-form-title">Вы можете связаться с нами через форму ниже. Наши специалисты ответят вам в ближайшее время</div>
                <form>
                    {this.fieldList && this.fieldList.map((field, index) =>
                        <label key={index}
                            className={
                                "" + (field.isFullLine ? "flex-100" : "")
                                + (field.type === "checkbox" && field.isHidden === false ? " checkbox" : "")
                                + (field.type === "checkbox" && field.isHidden === true ? " human" : "")
                            }
                        >
                            {field.label}{field.isRequired ? <span className="red"> *</span> : ""}:
                            {field.type === "text" &&
                                <input type="text" name={field.name} value={this.state[field.name].value} onChange={this.onFieldsChange} />
                            }
                            {field.type === "textarea" &&
                                <textarea type="text" name={field.name} value={this.state[field.name].value} onChange={this.onFieldsChange} />
                            }
                            {field.type === "checkbox" &&
                                <input type="checkbox" name={field.name} onChange={this.onFieldsChange} value={this.state[field.name].value} checked={this.state[field.name].value} />
                            }
                        </label>
                    )}
                    <label className="flex-100"><span className="red">*</span> - Поля, обязательные для заполнения</label>
                    {this.state.successText &&
                        <label className="flex-100 success-text">{this.state.successText}</label>
                    }
                    {this.state.errorText && 
                        <label className="flex-100 error-text">{this.state.errorText}</label>
                    }
                    {this.state.errorList && this.state.errorList.map((error, index) =>
                        <label key={index} className="flex-100 error-text">{error}</label>
                    )}
                    <div className="form-submit">
                        <input type="button" value="Отправить" onClick={this.sendForm} />
                    </div>
                </form>
            </div>
        );
    }
}
